import * as _ from 'lodash';
import { WithActions } from 'projects/mypay4-fe-common/src/public-api';

import { Debito } from './debito';
import { Person } from './person';
import { Spontaneo } from './spontaneo';

export class ItemCarrello extends WithActions {
  id: number;
  causale: string;
  causaleVisualizzata: string;
  bilancio: string;
  importo: number;
  deEnte: string;
  thumbEnte: string;
  codIpaEnte: string;
  codTipoDovuto: string;
  deTipoDovuto: string;
  iud: string;
  datiSpecificiRiscossione: string;
  identificativoUnivocoFlusso: string;
  codIuv: string;
  // Message from validateForm() when error occurs;
  errorMsg: string;
  //detail of intestatario
  intestatario: Person;
  details: object[];

  multibeneficiario: boolean;

  // Used when mail intestatario is null and mail is compulsory
  versanteEmail: string;

  static isDebito(elem: ItemCarrello): elem is Debito {
    return !this.isSpontaneo(elem);
  }

  static isSpontaneo(elem: ItemCarrello): elem is Spontaneo {
    return _.isNil(elem?.['codIuv']);
  }
}
