// THIS FILE IS GENERATED AUTOMATICALLY DURING BUILD
// SHOULD NOT BE EDITED MANUALLY
import { version } from '../../../../../package.json';
export const versionInfo = {
  buildTime:'2024-04-12T10:10:50+02:00',
  gitHash:'294814fa3',
  branchName:'develop',
  tag:'v1.1.6',
  version:version
};
